import {Link} from 'react-router-dom';
import '../routeStyles/navbarStyles.css';
import 'bootstrap/dist/css/bootstrap.css';
import useWindowDimensions from './setNavbar';
import {useLocation} from 'react-router-dom';

export default function NavigationBar(){
    //get the window dimensions
    const { width } = useWindowDimensions();
    const location = useLocation();
    let navBG = '';

    location.pathname === '/' || location.pathname === '/navigation' ? navBG= 'NavBG-Blue' : navBG= 'NavBG-Default'
    
    if (location.pathname === '/projects'){
        navBG='NavBG-Dark'
    } 

    const Set_mobilenavbar = (setBGColor:string) =>{
        const location = useLocation();
        //to create the 'closed' route effect, we want the route to be able to switch routes dynamically
        //the route will be a inverse of its current page. That between '/navigation' and '/'
        let nextNav;
        location.pathname === '/navigation' ? nextNav = '/' : nextNav = '/navigation'

        return (
            <nav className={'MobileNav container ' + setBGColor}>
                <Link style={{textDecoration: 'none'}} to={nextNav} className='MobileNavLink' > 
                <p className='responsive-header-font'> ME <br/> NU</p> 
                </Link>
            </nav>
        )
    }


    const Set_fullnavbar = (setBGColor:string)=>{
        
        return (
        <nav className={'container-fluid fullNav ' + setBGColor} >
            <ul className='Nav-element'>

            <Link to='/' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p  className='responsive-sentances-font'>Overview </p> 
            </Link>

            <Link to='/about' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>About </p> 
            </Link>

            <Link to='/projects' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>Projects </p> 
            </Link>

            <Link to='/contact' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>Contact </p> 
            </Link>
    
            </ul>
       </nav> 
        )
    }


     // function for setting the appropriate nav
    const DisplayNav =(props:any)=>{

        if(props.vp_Width < 450){
        //display mobile

        return Set_mobilenavbar(navBG)
        } else{

            return Set_fullnavbar(navBG)
        }
    }

    return (
        < DisplayNav vp_Width={width} />
    )   
}