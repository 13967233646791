import {motion} from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.css';
import emailjs from '@emailjs/browser';
import {useState, useRef} from 'react';
import {EMAILJS_PUBLIC_KEY, EMAILJS_serviceID, EMAILJS_templateID} from './emailJS';


export default function ContactForm() {
  
  //Variants for Animations
  const [displayPopup, setDisplaypopup] = useState({style: 'popup_hide', popupState:false, sendState:false })
  const [paperPlaneURL, setpaperPlaneURL] = useState('https://marketplace.canva.com/I-AgY/MAE1ScI-AgY/1/tl/canva-MAE1ScI-AgY.png');
  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault(); // Prevents default refresh by the browser

    emailjs.sendForm(EMAILJS_serviceID, EMAILJS_templateID, form.current, EMAILJS_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert("Email Sent 🚀")

    };

  const animation = {
    y: displayPopup.popupState ? [0, 200, 0] : 0,
    scale: displayPopup.popupState ? [1, 0.6, 1] : 1,
    rotate: displayPopup.sendState ? [0, 20, 0]: 0
  }


      //Img on hover function
      const PopupIMG=(state)=>{
        let send = displayPopup.sendState;
        let show = {style: 'popup_show', popupState:true, sendState: send}
        let hide = {style: 'popup_hide', popupState:false, sendState: send}
  
        state ? setDisplaypopup(show) : setDisplaypopup(hide); 
      }

      //share animation
      const sendAnimation = () =>{
        let show = {style: 'popup_show', popupState:true, sendState:true}
        setDisplaypopup(show);
      setpaperPlaneURL('https://marketplace.canva.com/siMwY/MAE1SesiMwY/1/tl/canva-MAE1SesiMwY.png');
      }

  
    return (
      <div className='container FormParent'>

        {/* <img src={contactIMG} alt='contact Image' className='img-fluid contactIMG' /> */}

        <form ref={form} onSubmit={sendEmail} className="ContactForm">
  
        <div className="form-group">
        <input name='user_name' type="text" className="responsive-head-font-small form-control" id="exampleInput" placeholder="Name" required/>
        </div>

        <div className="form-group">
        <input name='user_email' type="email" className="responsive-head-font-small form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" required/>
        </div>

        <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">Subject matter</label>
            <select name="user_subject" className="responsive-head-font-small form-control" id="exampleFormControlSelect1" >
            <option>I Have A Project Proposal</option>
            <option>I Want To Hire Your Services For A Project</option>
            <option>General Inquiries</option>
            <option>How Did you build such an amazing website?</option> 
            </select>
        </div>

        <div className="form-group">
        {/* <input name='message' type="text" className="form-control" id="exampleInput" placeholder="Message" /> */}
        <textarea className="responsive-head-font-small form-control" id="exampleFormControlTextarea1" name='user_message' rows={3} placeholder="Message"></textarea>
        </div>


          <br />
  
        <div className="submitBTN">
           {/* <motion.img initial={true} animate={{y:[200, 0, 200]}} transition={{type: 'spring', repeat:Infinity, duration:5}} className='popup_show' src="https://marketplace.canva.com/3oeDY/MAFBem3oeDY/1/tl/canva-MAFBem3oeDY.png" /> */}
          <motion.img animate={animation} transition={{type: 'spring', repeat:1, duration:5}} className='paperPlane' src={paperPlaneURL} />
          <motion.button onHoverStart={()=> PopupIMG(true) } onHoverEnd={() => PopupIMG(false)} onClick={()=> sendAnimation()} type="submit">Send Letter</motion.button>
        </div>

        </form>
      </div>
    );
  }