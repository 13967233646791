import {useLocation} from 'react-router-dom';
import {motion} from 'framer-motion';
import React from "react";

interface Props {
    children:any
}


const Page =(child:any, cursorState:Boolean)=> {
    return(
      {child}
    ) 
  }

const Transition: React.FC<Props> = ({ children, ...props }) => {
    const location = useLocation();

        //animation varients
        const variants = {
          hidden: { opacity: 0.5, x: -200, y: 0, duration:2 },
          enter: { opacity: 1, x: 0, y: 0 },
          exit: { opacity: 0.5, x: 0, y: -100, },
      }


    return (
        <motion.div
          key={location.pathname}
          variants={variants} 
          initial="hidden" 
          animate="enter" // Animated state to variants.enter
          exit="exit" // Exit state (used later) to variants.exit
          transition={{ type:'linear', ease: [0.7, 0, 0.5, 1] }} 
          
        >  

          {children}
          
        </motion.div>
    );
  };

  export default Transition;