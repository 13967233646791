import 'bootstrap/dist/css/bootstrap.css';
import './aboutStyles/aboutStyles.css';
import {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { getpageContentURL, getservicesURL } from '../../components/APIURL';
import SkillsContainer from './ServicesComponent';
import Transition from '../../routes/Transition';
import {motion} from 'framer-motion';
import useMouse from "@react-hook/mouse-position";
import useWindowDimensions from '../../routes/Navbar/setNavbar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

type PageContentType = {
  HeadDesc: string,
  Description: string
}

export default function About(){
    const [pageContent, setpageContent] = useState({Description:'', HeadDesc:''});
    const [serviceContent, setServiceContent] = useState({SkillPanels:[]})
    
    const [cursorVariant, setCursorVariant] = useState("default");
    const [cursorText, setCursorText] = useState("");
    const {width } = useWindowDimensions();
    const [ShowCursor, setshowCursor] = useState(Boolean);
    const [SkeletonLoader, setSkeletonloader] = useState(true);

    const ref = useRef(null);
    
    let mouseXPosition:any = 50;
    let mouseYPosition:any = 50;
    
    const servicesEnter =(event:any)=> {
        setCursorText("⚙");
        setCursorVariant("services");
    }
    
    const servicesLeave =(event:any)=> {
        setCursorText("");
        setCursorVariant("default");
    }

    const containerEnter =(event:any)=> {
        setCursorText("🤔");
        setCursorVariant("container");
    }
    
    const containerLeave =(event:any)=> {
        setCursorText("");
        setCursorVariant("default");
    }

    const CursorAnimation =()=> {
        const mouse = useMouse(ref, {
          enterDelay: 50,
          leaveDelay: 50
        });


      
        if (mouse.x !== null) {
          mouseXPosition = mouse.clientX;
        }
      
        if (mouse.y !== null) {
          mouseYPosition = mouse.clientY;
        }
    
        const Cursorvariants = {
            default: {
              opacity: 1,
              height: 15,
              width: 15,
              fontSize: "16px",
              backgroundColor: "#E072A4",
              x: mouseXPosition,
              y: mouseYPosition,
              transition: {
                type: "spring",
                mass: 0.6
              }
            },
            project: {
                opacity: 1,
                // backgroundColor: "rgba(255, 255, 255, 0.6)",
                backgroundColor: "#fff",
                color: "#000",
                height: 80,
                width: 80,
                fontSize: "18px",
                x: mouseXPosition - 32,
                y: mouseYPosition - 32
              },
    
              container: {
                opacity: 1,
                // backgroundColor: "rgba(255, 255, 255, 0.6)",
                backgroundColor: "#fff",
                color: "white",
                height: 80,
                width: 80,
                fontSize: "18px",
                x: mouseXPosition - 10,
                y: mouseYPosition - 10
              },
            services: {
              opacity: 1,
              backgroundColor: "#3122F8",
              color: "white",
              height: 64,
              width: 64,
              fontSize: "32px",
              x: mouseXPosition - 48,
              y: mouseYPosition - 48
            }
          };
    
        const spring = {
            type: "spring",
            stiffness: 500,
            damping: 28
          };

          return (
         <motion.div
          variants={Cursorvariants}
          className="circle"
          animate={cursorVariant}
          transition={spring}
        >
          <span className="cursorText">{cursorText}</span>
        </motion.div> 
          )
    }




    useEffect( ()=>{ 

      width > 750 ? setshowCursor(true): setshowCursor(false);
      
      async function getServiceContent() {
        const res = await axios.get(getservicesURL);
        let content = {SkillPanels:[]}
        content.SkillPanels = res.data;
        setServiceContent(content);
      }

        async function getContent() {
          const res = await axios.get(getpageContentURL+"AboutPage");
          let {HeadDesc, Description} = res.data;
          let Updatecontent:PageContentType = {HeadDesc, Description}

          setpageContent(Updatecontent);
          setSkeletonloader(false);
        }
        getContent();
        getServiceContent();

    }, []);
    // console.log(serviceContent.SkillPanels)

    return (  
        <div ref={ref}> 
        <Transition>  
        { ShowCursor ? <CursorAnimation /> : ' '}

        <section className="AboutSection">
       
        <div className='container'> 
            <div className='aboutHeader row'>
            <div className='headerTitle-wrapper col-5'> 
            <div className='bg-element '> </div>
                <h2 className='header_title  responsive-header-font-small'                >
                    Passionate, <br/>
                    Creative,  <br />
                    Developer
                </h2>
            </div>

                <div className='responsive-text-font header_content col-7'
                onMouseEnter={containerEnter}
                onMouseLeave={containerLeave}   
                >
                   {
                   
                   SkeletonLoader ? (                   <Stack spacing={1}>
                   <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
                   <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
                   <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-4' />
                 </Stack>)
                  :
                   (pageContent.HeadDesc)
                   
                   }
                </div>
            </div>

            <div className='row descriptionText'>
                <p className='responsive-sentances-font'>      
                {  
                  SkeletonLoader ? (                   <Stack spacing={1}>
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-10' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-10' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-10' />
                  <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-7' />
                </Stack> )
                 :
                 (pageContent.Description)
                  
                  }
                 </p>
            </div>

            <div className='bottomTitle-wrapper col-6'> 
            <div className='bg-element '> </div>
                <h2 className='header_title  responsive-header-font-small'>
                    What I <br/>
                    Can Do/  <br />
                    My Services
                </h2>
            </div>

            <div className='SkillsContainer'
            onMouseEnter={servicesEnter}
            onMouseLeave={servicesLeave}
            >
            <SkillsContainer Services={serviceContent.SkillPanels} />
            </div>

        </div>
        
        </section>
        </Transition>
        </div>
    )
}