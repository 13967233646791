import {useState } from 'react';
import { motion} from 'framer-motion';

export default function SkillsContainer(props:any){

    const ContentObj= [];
    const Content = props.Services.SkillPanel;
    for (const property in Content) {
        ContentObj.push(Content[property])
    }


    const Services = (props:any)=>{
        const [arrowDirection, setDirection] = useState(false);
        const ToggleAnimation = {
          rotate: arrowDirection ? 180 : 0,
          fill: arrowDirection ? '#3122F8' : '#000000',
          stroke: arrowDirection ? '#3122F8' : '#000000'
    
        }
         let contentState = arrowDirection? 'filter_content': 'filter_content-hidden';

        
        const Toggle=()=>{
          setDirection(!arrowDirection)
        }

        return (
        <div  onClick={()=>Toggle()} >
        <div  className="filter-header" >
            <motion.svg animate={ToggleAnimation} transition={{type: 'spring'}} xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="" className="toggle" >
            <rect x="0.566284" y="1.41748" width="9.78055" height="9.78055" fill="" stroke=""/>
        </motion.svg>
            <h3 className='Service-font'> {props.value.Title} </h3>
            <h3 className='Service-font'>{props.value.Number}</h3>
        </div>
        
        <ul className={contentState} >
        <li key={props.index + 1}> <p className='responsive-text-font-small'> {props.value.Content} </p></li>
        </ul>
        
        </div>
        )
    }


    return (
    <div className="filter_container">
        {
            ContentObj.map((value, index:any) => {
              return (
                <Services value={value} index={index} key={index + '001'}/>
              )
            })
            
        }
    </div>
    
    )
}