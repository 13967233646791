
export const EMAILJS_serviceID= process.env.REACT_APP_EMAILJS_serviceID

export const EMAILJS_templateID=process.env.REACT_APP_EMAILJS_templateID

export const EMAILJS_PUBLIC_KEY= process.env.REACT_APP_EMAILJS_PUBLIC_KEY


// export default {
//     SERVICE_ID: EMAILJS_serviceID, //service ID
//     TEMPLATE_ID: EMAILJS_templateID, //templateID
//     PUBLIC_KEY: EMAILJS_PUBLIC_KEY // public key
//     }