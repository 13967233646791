import {useLocation, Route, Routes} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

//our pages
import Overview from '../pages/overviewPage/Overview';
import About from '../pages/aboutPage/About';
import Project from '../pages/projectPage/Project';
import Contact from '../pages/contactPage/Contact';
import Mobilenav from './mobileNav/Mobilenav';



export default function AnimatedRoutes(){
    const location = useLocation();

    return (
    <AnimatePresence initial={false} exitBeforeEnter> 
        <Routes location={location} key={location.pathname}>
            <Route path='/' element={< Overview/>} />
            <Route path='/about' element={< About/>} />
            <Route path='/projects' element={< Project/>} />
            <Route path='/contact' element={< Contact/>} />
            <Route path='/navigation' element={< Mobilenav active={true}/>} />
        </Routes>
    </AnimatePresence>
    )
}