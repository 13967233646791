import 'bootstrap/dist/css/bootstrap.css';
import './overviewStyles/ovSectionTwoStyles.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {useEffect, useState, useRef} from 'react';
import { getDisplayURL } from '../../components/APIURL';
import axios from 'axios';

import {motion} from 'framer-motion';


type displayType = {
    Title: string,
    Description: string,
    imgURL: string,

    ProjectBrief: {
        Category: string,
        my_contribution: string,
        Tools: string,
    }
}

export default function OVSectionTwo (props:any){
    const [Displayimg, setDisplayimg] = useState('');
    const [SkeletonLoader, setSkeletonloader] = useState(true);
    const [projectloaded, setprojectloaded] = useState(false);

    const ref = useRef(null);

    let [Displaynum, setdisplaynum] = useState(1);
    let [DisplayContent, Setdisplaycontent] = useState({
        Title: '',
        Description: '',
        imgURL: '',
    
        ProjectBrief: {
            Category: '',
            my_contribution: '',
            Tools: '',
        }
    });

    const nextDisplay= (addOne:boolean, currentNum:number)=>{
        setprojectloaded(false);

        const num_addOne =(currentNum:number)=>{
            currentNum <= 2 ? setdisplaynum(currentNum +=1) : setdisplaynum(currentNum -=2)
            updateContent(currentNum);      
        }
        const num_subOne =(currentNum:number)=>{
            currentNum > 1 ? setdisplaynum(currentNum -=1) : setdisplaynum(currentNum +=2)
            updateContent(currentNum);       

        }

        addOne === true ? num_addOne(currentNum) : num_subOne(currentNum)
    }

   const updateContent = async(position:number)=>{
        // console.log(`current position: ${position}`);
        // console.log(`Display0${position}`);

        const res = await axios.get(getDisplayURL+`Display0${position}`);

        const Update:displayType = {
            Title: res.data.Title, 
            Description: res.data.Description,
            imgURL: res.data.imgURL,
        
            ProjectBrief: {
                Category: res.data.ProjectBrief.Category,
                my_contribution: res.data.ProjectBrief.my_contribution,
                Tools: res.data.ProjectBrief.Tools,
            }
        }

        Setdisplaycontent(Update);
        setDisplayimg(Update.imgURL);  
        setprojectloaded(true);
   }

    useEffect( ()=> {
        //Moniter if component is mounted
        let isMounted = true;

        updateContent(1);
        setSkeletonloader(false);
        setprojectloaded(true);

        return () => {
            //When Component is unmount
            isMounted = false;
        }
    }, [])

    


    return (
        <section className='ovSectionTwo' ref={ref}> 
        
        <div className='container'> 
            <div className='overviewHeader row'>
            
                <h2 className='header_title col-5 responsive-header-font-small'>
                    Drafted, <br/>
                    Hand Built, & <br />
                    Shipped With Love
                </h2>

                <div className='responsive-text-font-small header_content col-7'
                    onMouseEnter={props.containerE}
                    onMouseLeave={props.containerL}
                >
                   {
                    SkeletonLoader ? 
                    (<Skeleton variant="text" sx={{ fontSize: '4em', bgcolor:'grey.900' }} className='col-12' />) 
                    :
                   (props.headerContent)
                   }
                </div>
            </div>

        <div className='ContentPanel container-fluid'>
        <motion.button whileHover={{x:5}} className='Content-btn cont-btn-prev' 
        onClick={()=> nextDisplay(false, Displaynum)}
        onMouseEnter={props.ArrLeftEnter}
        onMouseLeave={props.ArrLeftLeave}
        > 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9" fill="none">
        <path d="M0.577686 4.67631C0.382425 4.48105 0.382425 4.16446 0.577686 3.9692L3.75967 0.78722C3.95493 0.591958 4.27151 0.591958 4.46677 0.78722C4.66204 0.982482 4.66204 1.29906 4.46677 1.49433L1.63835 4.32275L4.46677 7.15118C4.66204 7.34644 4.66204 7.66303 4.46677 7.85829C4.27151 8.05355 3.95493 8.05355 3.75967 7.85829L0.577686 4.67631ZM23.382 4.82275H0.93124V3.82275H23.382V4.82275Z" fill="black"/>
        </svg>
         </motion.button>

            <div className='Content-img '
                onMouseEnter={props.projectEnter}
                onMouseLeave={props.projectLeave}
            >
            <p className='responsive-text-font-small Project-number'> Project 0{Displaynum} </p>
            
            {    
            projectloaded ?         
            <motion.img whileHover={{x:-5, y:-6, scale: 0.95} } src={Displayimg} className='Display-img img-fluid' alt="...loading" />
            : 
            <Skeleton variant="rounded" className='img-full ' height={'25vh'} sx={{ bgcolor: 'black' }} />

            }

            </div> 


        <motion.button whileHover={{x:-5}} className='Content-btn cont-btn-nxt' 
        onClick={()=> nextDisplay(true, Displaynum)}
        onMouseEnter={props.ArrRightEnter}
        onMouseLeave={props.ArrRightLeave}
        >

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9" fill="none">
        <path d="M23.0847 4.67631C23.2799 4.48105 23.2799 4.16446 23.0847 3.9692L19.9027 0.78722C19.7074 0.591958 19.3908 0.591958 19.1956 0.78722C19.0003 0.982482 19.0003 1.29906 19.1956 1.49433L22.024 4.32275L19.1956 7.15118C19.0003 7.34644 19.0003 7.66303 19.1956 7.85829C19.3908 8.05355 19.7074 8.05355 19.9027 7.85829L23.0847 4.67631ZM0.280396 4.82275H22.7311V3.82275H0.280396V4.82275Z" fill="black"/>
        </svg>
         </motion.button>

        </div>

        </div>

        <div className='container-fluid DisplayContentParent'>
        <div className='DisplayContent'> 

        
        <h3 className='ProjectTitle'>
       { 
       projectloaded ? (DisplayContent.Title) : <Skeleton variant="text" width={'100%'} sx={{ fontSize: '2em', bgcolor:'grey.900' }} />
            
       }
        </h3>
       

        <div className='ProjectDesc responsive-sentances-font'>
       { 
       projectloaded ? (DisplayContent.Description) 
       : 
       <Stack spacing={1}>
       <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
       <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
       <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
       <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-11' />
       <Skeleton variant="text" sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-11' />
     </Stack>
       }
        </div>

        <h3 className='ProjectTitle'>App Details</h3>
        <div className='ProjectDetails row'>
    
{
           projectloaded ? (
        <div className='col'>
            <h3 className='responsive-text-font-small'> My Contribution </h3>
            <p className='responsive-sentances-font'> 
                {DisplayContent.ProjectBrief.my_contribution}
            </p>
        </div> ) :  <Skeleton variant="text" height={10} sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-4' />
}

{        projectloaded ?
(        <div className='col'>
            <h3 className='responsive-text-font-small'> Tools </h3>
            <p className='responsive-sentances-font'> 
            {DisplayContent.ProjectBrief.Tools}

            </p>
        </div>) :  <Skeleton variant="text" height={10} sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-4' />
}

{       projectloaded ?
        (<div className='col'>
            <h3 className='responsive-text-font-small'> Category</h3>
            <p className='responsive-sentances-font'> 
                {DisplayContent.ProjectBrief.Category}
            </p>
        </div>) :  <Skeleton variant="text" height={10} sx={{ fontSize: '1em', bgcolor:'grey.900' }}  className='col-4' />
}

        </div>
        </div>
        </div>
        </section>
    )
}

