import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../routeStyles/footerStyles.css';
import { motion} from "framer-motion";

export default function Footer(){
    
    const ContactButton =()=>{


        return (
            //this is the parent container that will read the cursors values
            <div className='container Contactbtn-parent'>

            <motion.div
            className='Contactbtn-child'
            drag
            dragConstraints={{ top: -30, right: 50, bottom: 30, left: -50 }}
            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
            dragElastic={0.5}
            whileTap={{ scale: 0.9, borderRadius: 10 }}
        > 
        <h3 className='ProjectTitle'> <Link to='/contact' style={{textDecoration: 'none', color: 'white'}}> Shoot An Email 🚀 </Link> </h3>
        </motion.div>


         </div>
        );

    }

    return(
        <footer>
        
        <div className='footer-container'>
        <div className='footer-title'>
            
            <div className='footer-profile'>
            <div className='profileIMG'>     
            <img  src="https://firebasestorage.googleapis.com/v0/b/ntwproductions.appspot.com/o/Contact.png?alt=media&token=28829a2f-64a9-42dc-9804-acb971735650" alt="img" />
            </div>

            <div className="line">  
                <svg width="139" height="3" viewBox="0 0 139 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="1.5" x2="139" y2="1.5" stroke="#F5F5F5" strokeWidth="3"/>
                </svg>
            </div>
            <div className="head"> 
            <h1 className='responsive-header-font'>Let's</h1>
            </div>

        </div>
        
        <h1 className='responsive-header-font'>Work Togeather </h1>
        </div>
        </div>
        
        <div className='FooterContactSection'>
            <hr className='Contactline'/>
            <h1 className='responsive-header-font-small'> Reach Out </h1>
            <div className="footer-nav"> 
            < ContactButton />
            <ul className='Nav-element col'>
            <Link to='/' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p  className='responsive-sentances-font'>Overview </p> 
            </Link>
            <Link to='/about' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>About </p> 
            </Link>

            <Link to='/projects' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>Projects </p> 
            </Link>

            <Link to='/contact' className='Nav-element' style={{textDecoration: 'none'}} > 
            <p className='responsive-sentances-font'>Contact </p> 
            </Link>
            </ul>
            </div>
            
            <hr className='Contactline'/>
        </div>


        
        <div className="footerSmall container-fluid">
        {/* This is one pair */}
        <div className='smallContainer-side row'> 
            <div className="smallContainer col-lg">
            <small className='row subHead responsive-footer-font'> Version </small>
            <small className='row responsive-footer-font'> 2022 Edition </small>
            </div>
            <div className="smallContainer col-lg">
            <small className='row subHead responsive-footer-font'> Freelance Status </small>
            <small className='row responsive-footer-font'> Active </small>
            </div>
            {/* < TrackCursor /> */}
        
        </div>

        <div className="smallContainer col-lg">
        <small className='row subHead subHead-right responsive-footer-font'> Catch Me On </small>
        <div className='row'> 
        <small className='col responsive-footer-font'> <a href="https://www.instagram.com/nigel_t.w/">Instagram</a> </small>
        <small className='col responsive-footer-font'> <a href="https://www.behance.net/nickyduce">Behance</a> </small>
        <small className='col responsive-footer-font'> <a href="https://github.com/NickyDuce">Github</a> </small>
        </div>
        </div>

        </div>

        </footer>
    )
}