import 'bootstrap/dist/css/bootstrap.css';
import './contactStyles/contactStyles.css';
import ContactForm from './ContactForm';
import Transition from '../../routes/Transition';
import useMouse from "@react-hook/mouse-position";
import {motion} from 'framer-motion';
import {useState, useEffect, useRef} from 'react';
import useWindowDimensions from '../../routes/Navbar/setNavbar';

export default function Contact(){
    const [cursorVariant, setCursorVariant] = useState("default");
    const [cursorText, setCursorText] = useState("");
    const { width } = useWindowDimensions();
    const [ShowCursor, setshowCursor] = useState(Boolean);


    const ref = useRef(null);
    const mouse = useMouse(ref, {
      enterDelay: 100,
      leaveDelay: 100
    });
  
    let mouseXPosition:any = 100;
      let mouseYPosition:any = 100;
    
      if (mouse.x !== null) {
        mouseXPosition = mouse.clientX;
      }
    
      if (mouse.y !== null) {
        mouseYPosition = mouse.clientY;
      }
  
      const Cursorvariants = {
          default: {
            opacity: 1,
            height: 15,
            width: 15,
            fontSize: "16px",
            backgroundColor: "black",
            x: mouseXPosition,
            y: mouseYPosition,
            transition: {
              type: "spring",
              mass: 0.6
            }
          },
          project: {
              opacity: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.6)",
              backgroundColor: "#fff",
              color: "#000",
              height: 80,
              width: 80,
              fontSize: "18px",
              x: mouseXPosition - 32,
              y: mouseYPosition - 32
            },
          contact: {
            opacity: 1,
            backgroundColor: "#FFBCBC",
            color: "white",
            height: 64,
            width: 64,
            fontSize: "32px",
            x: mouseXPosition - 48,
            y: mouseYPosition - 48
          },
          container: {
              opacity: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.6)",
              backgroundColor: "#fff",
              color: "white",
              height: 80,
              width: 80,
              fontSize: "32px",
              x: mouseXPosition - 10,
              y: mouseYPosition - 10
            }
        };
  
      const spring = {
          type: "spring",
          stiffness: 500,
          damping: 28
        };

      useEffect(()=>{
        width > 750 ? setshowCursor(true): setshowCursor(false);
      
      }, []);     

    return (
        <Transition>
        <section className="contactSection" ref={ref}>

        {    ShowCursor ? <motion.div
          variants={Cursorvariants}
          className="circle"
          animate={cursorVariant}
          transition={spring}
        > 
          <span className="cursorText">{cursorText}</span>
        </motion.div> :''

        } 

        <div className='contactHeader row'>
        <div className='headerTitle-wrapper col-lg'> 
            <div className='bg-element '> </div>
                <h2 className='header_title  responsive-header-font'>
                    Have an Idea? <br/>
                    Let's Build,  <br />
                    And Launch It
                </h2>
            </div>
        </div>

        <div className='ContactParent container-full'>

        <div className='ContactTitle'>
            <h2>
                Get In Touch
            </h2>
        </div>

        <ContactForm />

        </div>

        </section>
        </Transition>
    )
}