
const baseURL = process.env.REACT_APP_API_BASEURL;

//Route to fetch image
const getImageURL = baseURL + "getImages/";

//Route to get Content for the showreel panel
const getDisplayURL = baseURL + "getDisplay/";

//Route to get page Content
const getpageContentURL = baseURL + "getpagecontent/";

//Route to get content for the service section in the about page
const getservicesURL = baseURL + "getservices";

//get all project
const getprojectsURL = baseURL + "getProjects";

export {getImageURL, getDisplayURL, getpageContentURL, getservicesURL, getprojectsURL}