import 'bootstrap/dist/css/bootstrap.css';
import './projectStyles/projectStyles.css'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {motion} from 'framer-motion';

export default function ProjectComponent(props:any) {

    const openProject = (url:string) => {
        console.log('clicked')
        return window.open(url, '_blank', 'noopener,noreferrer');
      };



    return (
      props.SkeletonLoader ? (
        <Stack spacing={0.5}>
          <Skeleton variant="rounded" width={550} height={220} sx={{ bgcolor: 'grey.900' }} />
          {/* <Skeleton variant="text" width={550}  sx={{ fontSize: '2em', bgcolor:'grey.900' }} /> */}
          <div className='row gap-2'>
          <Skeleton variant="text" width={350}  sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='col-6' />
          <div className="col-2"></div>
          <Skeleton variant="text" width={75}  sx={{ fontSize: '1em', bgcolor:'grey.900' }} className='.col-2' />
          </div>
        </Stack>
            )
            :
(          <div className="projectContainer" 
      onMouseEnter={props.animationL}       
      >
        
        <div className="projectIMG">

        <motion.img className='img-fluid' 
        alt='Loading project coverimage...' 
        src={props.Details.CoverURL} 
        onMouseEnter={props.animationE}
        onMouseLeave={props.animationL}
        whileHover= {{scale: 0.9}}
        transition= {{type:'spring', mass: 2}}
        onClick={()=> {openProject(props.Details.projectURL)}}    
        />

        </div>

        <div className="projectExplanation">
          
          <h2 className='title responsive-header-font-small'> {props.Details.Title}</h2>
          <hr/>
          <div className="details">
            <h3 className='responsive-text-font Category'>{props.Details.Category}</h3>
            <h3 className='responsive-text-font Year'>{props.Details.Year}</h3>
          </div>
        </div>
      </div>)
    )
  }