import '../routeStyles/navbarStyles.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Link} from 'react-router-dom';
import {motion, AnimatePresence} from 'framer-motion';

export default function Mobilenav(props:any){

    const menuVarient= {
        positionL:{
             x: 300, 
             opacity: 0,
             scale:0.5 
        },
        positionR:{
             x: -300, 
             opacity: 0,
             scale:0.4 
        },

        slidine:{
             x: 0, 
             opacity: 1,
             scale:1,
             type: 'spring'
        },
        exit:{
            x: -300, 
            opacity: 0 
        }
    }

    return (
        <section className='MobileSection'>

            <ul className='Nav-element container'>

            <AnimatePresence>
                <motion.div className='mobile-nav' 
                key={props.active}
                variants={menuVarient}
                initial={"positionL"}
                animate={"slidine"}
                exit={"exit"}
                transition={{duration:0.5}}
                > 
                <Link to='/' style={{textDecoration: 'none'}} 
                    className='Nav-element navEle01'> 
                    Overview
                    <div className='navbanner'> </div>
                </Link> 
                </motion.div>
            </AnimatePresence>

            {/* About page menu */}
            <AnimatePresence> 
            <motion.div className='mobile-nav'
            key={props.active}
            variants={menuVarient}
            initial={"positionR"}
            animate={"slidine"}
            exit={"exit"}
            transition={{duration:0.5, delay:0.2}}
            > 
            <Link to='/about' style={{textDecoration: 'none'}} className='Nav-element navEle02'>About 
                <div className='navbanner'> </div>
            </Link> 
            </motion.div>
            </AnimatePresence>

            {/* Project Page Menu */}
            <AnimatePresence> 
            <motion.div className='mobile-nav'
            key={props.active}
            variants={menuVarient}
            initial={"positionL"}
            animate={"slidine"}
            exit={"exit"}
            transition={{duration:0.5, delay:0.4}}
            > 
            <Link to='/projects' style={{textDecoration: 'none'}} className='Nav-element navEle03'> Projects 
                <div className='navbanner'> </div>
            </Link> 
            </motion.div>
            </AnimatePresence>

            {/* Contact page Menu  */}
            <AnimatePresence> 
            <motion.div className='mobile-nav'
            key={props.active}
            variants={menuVarient}
            initial={"positionR"}
            animate={"slidine"}
            exit={"exit"}
            transition={{duration:0.5, delay:0.6}}
            > 
            <Link to='/contact' style={{textDecoration: 'none'}} className='Nav-element navEle04'> Contact 
                <div className='navbanner'> </div>
            </Link>
            </motion.div>
            </AnimatePresence>    

            </ul>
        </section>
    )
}