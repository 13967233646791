import 'bootstrap/dist/css/bootstrap.css';
import './projectStyles/projectStyles.css'
import Transition from '../../routes/Transition';
import axios from 'axios';
import {motion} from 'framer-motion';
import useMouse from "@react-hook/mouse-position";
import {useState, useEffect, useRef} from 'react';
import useWindowDimensions from '../../routes/Navbar/setNavbar';
import {getprojectsURL} from '../../components/APIURL';

import ProjectComponent from '../projectPage/ProjectComponent';

type ProjectsType = {
  Title: string,
  CoverURL: string,
  Category: string,
  Year: string,
  projectURL: string
}

export default function Project(){

    const [cursorVariant, setCursorVariant] = useState("default");
    const [cursorText, setCursorText] = useState("");
    const [projectDetails, setprojectDetails] = useState([{}])
    const [SkeletonLoader, setSkeletonloader] = useState(true);
    const { width } = useWindowDimensions();
    const [ShowCursor, setshowCursor] = useState(Boolean);

    const ref = useRef(null);
    const mouse = useMouse(ref, {
      enterDelay: 100,
      leaveDelay: 100
    });

    let mouseXPosition:any = 100;
    let mouseYPosition:any = 100;
  
    if (mouse.x !== null) {
      mouseXPosition = mouse.clientX;
    }
  
    if (mouse.y !== null) {
      mouseYPosition = mouse.clientY;
    }

    const Cursorvariants = {
        default: {
          opacity: 1,
          height: 15,
          width: 15,
          fontSize: "16px",
          backgroundColor: "#455CE9",
          x: mouseXPosition,
          y: mouseYPosition,
          transition: {
            type: "spring",
            mass: 0.6
          }
        },
        project: {
            opacity: 1,
            backgroundColor: "#3122F8",
            color: "white",
            height: 80,
            width: 80,
            fontSize: "18px",
            x: mouseXPosition - 32,
            y: mouseYPosition - 32,
            rotate: [0,360,0]
          }
      };

    const spring = {
        type: "spring",
        stiffness: 500,
        damping: 28
      };

      function projectEnter(event:any) {
        setCursorText("View");
        setCursorVariant("project");
      }
    
      function projectLeave(event:any) {
        setCursorText("");
        setCursorVariant("default");
      }

      useEffect( ()=>{
        width > 750 ? setshowCursor(true): setshowCursor(false);

        const storeProj:any = []
        const Allprojects:any= []
        
        async function getProjects() {
          const res = await axios.get(getprojectsURL);
          const projects:any= []
          const rawProj = res.data;
          rawProj.forEach((item:any)=>{
            projects.push(item._fieldsProto)
          })

          projects.forEach((item:any)=> {
            // stringValue
        storeProj.push(item)
          })
          let temp={
            Title:'',
            CoverURL:'',
            Category:'',
            Year:'',
            projectURL:''
          }
          storeProj.forEach((item:any)=>{
            let temp:ProjectsType={
              Title: item.Title.stringValue,
              CoverURL: item.CoverURL.stringValue,
              Category: item.Category.stringValue,
              Year: item.Year.stringValue,
              projectURL: item.projectURL.stringValue
            }
            Allprojects.push(temp);
          })
          // console.log("Refined: ", Allprojects)
          setprojectDetails(Allprojects);
          setSkeletonloader(false);
        }
        getProjects()
      },[])

      


    return (
        <Transition>
        <section className="projectSection" ref={ref}>
        
        {    ShowCursor ? <motion.div
          variants={Cursorvariants}
          className="circle"
          animate={cursorVariant}
          transition={spring}
        > 
          <span className="cursorText">{cursorText}</span>
        </motion.div> :''

        }  

        <div className='Title-wrapper col'> 
            <div className='bg-element'> </div>
                <h2 className='header_title  responsive-header-font'>
                    Striving To Create <br/>
                    Unique Experiences
                </h2>
            </div>

            <div className='text container-full'>
                <p className='responsive-text-font-small'> (This page is currently being updated and improved for a better and more informative experience)  </p>
            </div>
            
            <div className="projectParent container-fluid">


            {

            projectDetails.map( (project, index) => 
              <ProjectComponent index={index} 
              key={index} 
              Details={project} 
              animationE={projectEnter} 
              animationL={projectLeave} 
              SkeletonLoader ={SkeletonLoader}
              />               
              )
            
            }

            </div>


        </section>
        </Transition>
    )
}