import React from 'react';
import ReactDOM from 'react-dom/client';
import './templateStyles/AppStyles.css';
import { BrowserRouter as Router} from 'react-router-dom';



//our routes
import NavigationBar from './routes/Navbar/Navbar';
import AnimatedRoutes from './routes/AnimatedRoutes';
import Footer from './routes/Footer/Footer';
import Transition from './routes/Transition';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <Router>
    <Transition > 
      <NavigationBar/>
      <AnimatedRoutes />
      <Footer />
    </Transition>
    </Router>
  </React.StrictMode>
);


